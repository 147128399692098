.message-enter {
	opacity: 0.01;
	// transform: scale(0.9) translateY(50%);
}
.message-enter-active {
	opacity: 1;
	// transform: scale(1) translateY(0%);
	transition: all 0.1s ease-out;
}
.message-exit {
	opacity: 1;
	// transform: scale(1) translateY(0%);
}
.message-exit-active {
	opacity: 0.01;
	// transform: scale(0.9) translateY(50%);
	transition: all 0.1s ease-out;
}

.wrapper {
	// width: 1170px;
	// margin: 0 auto;
}
