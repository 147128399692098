$dark: black;

* {
	-webkit-transition: all 0.4s linear;
	-moz-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
	font-family: 'Ubuntu', sans-serif;
}
.slide-up {
	animation: slide-up 0.4s ease;
	@keyframes slide-up {
		0% {
			opacity: 0;
			transform: translateY(20px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

img {
	display: block;
	width: 100%;
}

h1,
h2,
h3 {
	font-weight: 300;
	line-height: 1.25;
}

h1 {
	// font-family:$font-serif;
}

h2 {
	// font-family:$font-serif;
	font-size: 1.85rem;
}

h3 {
	font-size: 1.4rem;
}

a {
	color: $dark;
	text-decoration: underline;
}

a:hover,
a:focus {
	text-decoration: none;
}
