// .loader{
//     position: fixed;
//     /* top: 50%; */
//     /* left: 50%; */
//     /* transform: translate(-50%, -50%); */
//     /* -webkit-transform: translate(-50%, -50%); */
//     font-size: 30px;
//     color: #415b76;
//     /* padding: 5px; */
//     z-index: 100;
//     /* position: fixed; */
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     z-index: 10;
//     background-color: white;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-image: url('~images/logo.svg');
//     background-repeat: no-repeat;
//     /* width: 80px; */
//     /* height: 80px; */
//     background-size: 80px 80px;
//     background-position: center;
// }

.a-logo {
	position: fixed;
	/* top: 50%; */
	/* left: 50%; */
	/* transform: translate(-50%, -50%); */
	/* -webkit-transform: translate(-50%, -50%); */
	font-size: 30px;
	color: #415b76;
	/* padding: 5px; */
	z-index: 100;
	/* position: fixed; */
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	background-image: url('~images/logo.svg');
	background-repeat: no-repeat;
	/* width: 80px; */
	/* height: 80px; */
	background-size: 80px 80px;
	background-position: center;
}
